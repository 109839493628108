.calendar-container {
  position: relative;
}

.calendar-popup {
  position: absolute;
  top: 40px;
  left: 0;
  border-radius: 10px;
  padding: 16px;
  z-index: 100;
}

.calendar {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-family: Arial, sans-serif;
  width: 300px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.calendar-header button {
  background: #007bff;
  color: white;
  border: none;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background 0.3s;
}

.calendar-header button:hover {
  background: #0056b3;
}

.calendar-header select {
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
}

.calendar-day:hover {
  background: #f0f0f0;
}

.calendar-day.selected {
  background: #007bff;
  color: white;
  font-weight: bold;
}

.calendar-day.empty {
  background: transparent;
  cursor: default;
}

