.container{
    display: flex;
    /* flex-direction: row; */
    background-color: #3384b115;
}

.sidebar{
    width: 50vw;
}

.content{
    width: 100vw;
    /* overflow-y: auto; */
}