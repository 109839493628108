.audit-reports-container{
  margin-left: 2%;
}

.audit-reports-header{
  font-family: "Nunito";
  font-size: 28px;
  font-weight: 600;
  line-height: 38.19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 20px;
  margin-bottom: 50px;
}

.audit-reports-header-table .p-datatable-thead > tr > th {
  background-color: #3384b1;
  color: white;
  font-weight: bold;
  text-align: center;
}

.calendar-icon-button-container {
  background-color: #3384b1;
  color: white;
  border: none;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 43px;
  width: 140px;
}

.search-calender-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.search-sub-calender-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.calendar-icon-button-container h1 {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.download-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 43px;
  border-radius: 5px 0px 0px 0px;
  border: solid 1px #3384b1;
  background-color: #ffffff;
  cursor: pointer;
}
.download-button-container h1 {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.08399999886751175px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3384b1;
}
