.formFlexClass {
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
}
.formInputWrapper {
  display: flex;
  flex-direction: column;
}
.locationInputWrapper {
  display: flex;
  flex-direction: column;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 30px 0 50px;
}
.formInputWrapper label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.formInputWrapper input {
  width: 400px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
}
.locationInputWrapper label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 5px;
}
.locationInputWrapper input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #b7b2b2;
}

.buttonWrapper button:first-child {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background-color: #3384b1;
  border: none;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}
.buttonWrapper button:nth-child(2) {
  font-family: Inter;
  font-size: 24px;
  font-weight: 300;
  color: #000000;
  background-color: #fff;
  border: 1px solid #000;
  width: 125px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}
.formProfileImg {
  text-align: center;
  margin: 15px 0 35px;
  position: relative;
  overflow: hidden;
}
.profileImage {
  width: 175px;
  border-radius: 50%;
  height: 175px;
}
.formProfileImgWrapper {
  position: relative;
}
/* .profileSelectionIcon {
  position: absolute;
  bottom: 15px;
  left: 655px;
} */
.overlayIcon {
  position: absolute;
  top: 78%;
  left: 56%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.disabledClass{
  background-color: #e6e6e6;;
  cursor: not-allowed;
}

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .formSection{
    width: 62rem;
  }
  .formInputWrapper input {
    width: 410px;
    height: 45px;
  }
  .locationInputWrapper input{
    height: 45px;
  }
  .overlayIcon{
    left: 58.5%;
  }
}

@media only screen and (max-width: 1025px) {
.formSection {
  width: 50rem;
}
.formInputWrapper input {
  width: 300px;
}
.overlayIcon {
  left: 61%;
}
}

@media only screen and (max-width: 450px){

  /* temporary fix */
  .rightCheckboxClass{  
    display: none;
  }
  /* temporary fix */


  .formSection{
    width: 90vw;
  }

  .profileTag{
    height: 43px;
  }
  
  .profileFormHeader{
    padding: 5px;
    width: 300px;
  }

  .profileFormHeaderWrapper span {
    top: 15px;
    right: 40px;
  }

  .profileFormHeaderWrapper span img{
    height: 20px;
    width: 20px;
  }

  .formProfileImg{
    height: 106px;
    margin-bottom:10px;
  }

  .formProfileImg img{
    height: inherit;
    width: 111px;
  }

  .formProfileImg label img{
    height: 27px;
    width: 27px;
  }
    
  form{
    font-size: 14px;
    font-weight: 400;
  }
  .formFlexClass{
    flex-direction: column;
    gap:15px;
    margin-bottom: 15px;
  }

  .formFlexClass  input{
    height: 35px;
  }

  .buttonWrapper{
    margin-top:10px;
  }

  
  .buttonWrapper button:first-child {
    height: 32px;
    width: 92px;
    font-size: 14px;
  }

  .buttonWrapper button:nth-child(2){
    height: 32px;
    width: 92px;
    font-size: 14px;
  }
}

/*CROP*/


.cropperContainer {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.cropperContainer h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.cropperContainer p {
  margin-bottom: 15px;
  font-size: 14px;
  color: #666;
}

.reactCropWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
}

.cropperButtons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.cropperButtons button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cropperButtons button:first-child {
  background-color: #4B70E2;
  color: white;
  border: 1px solid #4B70E2;
}

.cropperButtons button:first-child:hover {
  background-color: #3a5bc7;
}

.cropperButtons button:first-child:disabled {
  background-color: #a0b0e0;
  border-color: #a0b0e0;
  cursor: not-allowed;
}

.cropperButtons button:last-child {
  background-color: white;
  color: #4B70E2;
  border: 1px solid #4B70E2;
}

.cropperButtons button:last-child:hover {
  background-color: #f5f8ff;
}

.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}