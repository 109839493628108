.manage-admins {
  margin-left: 2%;
}

.manage-admins-heading{
  font-family: "Nunito";
  font-size: 28px;
  font-weight: 600;
  line-height: 38.19px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 20px;
  margin-bottom: 40px;
}

.add-admin-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.addAdminButton {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 400;
  background-color: #3384b1;
  color: #fff;
  width: 150px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  gap: 7px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 40%;
  height: 43px;
  background: #ffffff;
  border: solid 2px #DFE1E6;
  border-radius: 3px;
}

.search-input {
  border: none;
  outline: none;
  cursor: pointer;
}

.admin-header-table .p-datatable-thead > tr > th {
  background-color: #3384b1;
  color: white;
  font-weight: bold;
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.sub-edit-button-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: #3384b1;
  border: solid 0.5px #3384b1;
  border-radius: 4px;
  width: 25%;
  cursor: pointer;
  height: 31px;
  padding: 5px;
  gap: 9px;
}

.sub-delete-button-container {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: #3384b1;
  border: solid 0.5px #ff0000;
  border-radius: 4px;
  width: 25%;
  height: 31px;
  cursor: pointer;
  padding: 5px;
  gap: 9px;
}

.button-icon {
  height: 20px;
  width: 20px;
}

.button-container-text {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.p-datatable {
  margin-top: 20px;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
}

/* Other elements */
.profileFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
  font-family: "Nunito";
}
.formSection {
  width: 60%;
  padding: 0rem 2rem;
  height: 70%;
  max-height: 80vh;
}

.profileFormHeaderWrapper {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: center;
}
.profileFormHeader {
  display: flex;
  background-color: #3384b1;
  border-radius: 0 0 20px 20px;
  width: 30rem;
  justify-content: center;
  gap: 30px;
  padding: 10px 0;
}

.profileTag.active {
  height: 3vw;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sub-formProfileImgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: #b7b2b2;
  margin-top: 20px;
  position: relative;
  overflow: hidden; 
}

.sub-formProfileImg {
  width: 100%;
  height: 100%;
  position: relative;
}

.sub-profileImage {
  width: 100%; 
  height: 100%;
  object-fit: cover; 
  border-radius: 50%;
}

.defaultProfileWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 30px;
}

.default-formProfileImg {
  height: 40px;
  width: 40px;
  text-align: center;
}

.editProfileIcon {
  position: absolute;
  bottom: 5px; 
  left: 50%;
  transform: translateX(-50%);
  /* background-color: white; */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.edit-icon {
  height: 24px;
  width: 24px;
}



.formFlexClass {
  gap: 40px;
  margin-bottom: 7px;
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.sub-formInputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-formInputWrapper input{
  height: 50px;
  border-radius: 4px;
  border: solid 1px #757575;
  outline: none;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 300;
  line-height: 24.55px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 30px 0 50px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.toggleContainer h1 {
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 400;
  line-height: 24.55px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 70px;
  color: #757575;
}

.deleteDialogWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  width: 80%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  transition: background 0.3s;

}

.deleteDialogHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.deleteDialogHeader h1 {
  font-size: 20px;
  margin: 0;
  color: #333;
}

.deleteDialogContent {
  margin: 20px 0;
  font-size: 16px;
  color: #555;
}

.deleteDialogContent p {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 300;
  line-height: 27.28px;
  text-align: center;
  color: #757575;
}

.deleteReasonInput {
  height: 130px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.deleteDialogButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.deleteButton {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  text-align: center;
  height: 46px;
}

.deleteButton:hover {
  background-color: #cc0000;
}

.cancelButton {
  background-color: #ccc;
  color: #757575;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Nunito";
  font-size: 18px;
  font-weight: 600;
  line-height: 24.55px;
  text-align: center;
}

.cancelButton:hover {
  background-color: #999;
}

.deleteIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.delete-button-container-icon {
  width: 38px;
  height: 38px;
  background-color: #f8dee2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-button-icon {
  height: 20px;
  width: 20px;
}

.deleteDialogHeaderTitle {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 600;
  line-height: 27.28px;
  text-align: left;
  color: #000000;
}


/* Tablet view (min-width: 768px to max-width: 1024px) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-datatable {
    margin-top: 20px;
    width: 95%;

  }

  .search-container {
    width: 40%;
  }

  .addAdminButton {
    font-size: 14px;
    width: 130px;
    height: 40px;
  }

  .button-container {
    width: 100%;
  }

  .sub-edit-button-container,
  .sub-delete-button-container {
    width: 50%;
    height: 35px;
  }

  .profileFormWrapper .formSection {
    width: 90%;
    padding: 1rem 1.5rem;
    height: 60%;
  }

  .formWrapper {
    gap: 25px;
  }

  .sub-formInputWrapper {
    width: 80%;
  }

  .buttonWrapper {
    gap: 20px;
    margin: 20px 0 40px;
  }

  .toggleContainer h1 {
    font-size: 16px;
  }

  .deleteDialogWrapper{
    width: 70%;
  }
}

/* Desktop view and above (min-width: 1024px and above) */
@media screen and (min-width: 1024px) {
  .p-datatable {
    margin-top: 20px;
    width: 90%;
  }

  /* .p-datatable .p-datatable-tbody > tr > td{
    padding: 10px;
  } */

  .search-container {
    width: 30%;
  }

  .addAdminButton {
    font-size: 20px;
    width: 150px;
    height: 43px;
  }

  .button-container {
    gap: 20px;
  }

  .sub-edit-button-container,
  .sub-delete-button-container {
    width: 100px;
    height: 31px;
  }

  .profileFormWrapper .formSection {
    width: 60%;
    padding: 0rem 2rem;
    height: 70%;
    max-height: 80vh;
  }

  .formWrapper {
    gap: 40px;
  }

  .sub-formInputWrapper {
    width: 100%;
  }

  .sub-formInputWrapper input{
    width: 100%;
  }

  .buttonWrapper {
    gap: 15px;
    margin: 30px 0 50px;
  }

  .toggleContainer h1 {
    font-size: 18px;
  }
  .deleteDialogWrapper{
    width: 40%;
  }
}