.createPolicyButton {
  display: flex;
  justify-content: flex-end;
  padding-right: 2%;
}

.policyWrapper {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 14%;
  margin-top: 17px;
}

.conditionalViewer {
  width: 43px;
  height: 43px;
  background: rgba(217, 217, 217, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.createPolicyButton > button {
  width: 165px;
  height: 49px;
  background: #3384b1;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 2%;
  border-radius: 5px;
  cursor: pointer;
}

.policyText.create {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 18px;
  width: -webkit-fill-available;
}

.policyTableWrapper {
  padding: 29px 135px 0 75px;
  display: flex;
  flex-direction: column;
  gap: 4vw;
  height: 67vh;
}

.policyGrid {
  display: flex;
  flex-wrap: wrap;
}

.policyTable {
  border-collapse: collapse;
  width: 100%;
}

.policyTable > thead {
  height: 52px;
  background-color: #f8f8f8;
}

.policyTable th {
  font-family: "Lexend";
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #787878;
  padding: 20px 0;
  color: #787878;
}

.policyTable th:first-child {
  border-left: none;
  border-radius: 10px 0 0 0;
}

.policyTable th:last-child {
  border-radius: 0 10px 0 0;
}

.policyTable td {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 45px;
  border-bottom: 1px solid #0000001a;
  padding: 5px 0;
}

td > svg {
  position: absolute;
  top: 27%;
  right: 1%;
}

/* ACTION MENU   */
.actionsContainer {
  box-shadow: 0px 4px 4px 0px #00000040;
  width: fit-content;
  font-family: "Nunito";
  font-size: 18px;
  position: absolute;
  top: 13%;
  left: 94%;
  z-index: 20;
  background: white;
}


.action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 7px;
  gap: 8%;
}

.no_policies {
  font-family: "Lexend";
  font-size: 37px;
  height: 61vh;
  /* width: 80vw; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0);
}

.paginationNumbers {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 2rem;
}

.table-and-pagination {
  display: flex;
  flex-direction: column;
  gap: 3vw;
}

.policies {
  height: 72%;
  width: 93%;
  display: flex;
  gap: 3rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 3rem;
  cursor: pointer;
  justify-content: flex-start;
  align-items: start;
}
.no_events {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-family: Nunito;
  font-size: 32px;
  font-weight: 300;
  line-height: 67.5px;
  text-align: center;
}

.policy_card {
  width: 20rem;
  border: 1px solid #3384b1;
  height: 10rem;
  border-radius: 15px;
  position: relative;
  background-color: #ffffff;
}
.policy_card > img {
  width: 100%;
  height: 100%;
}

.policy_name {
  /* position: absolute;
    left: 10%;
    top: 50%; */
  font-family: Nunito;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  margin: 0;
}

.active-btn {
  margin-top: 0;
  background-color: #3384b1 !important;
  color: #ffffff !important;
}
.policy_details {
  font-size: 16px;
  font-family: Nunito;
  font-weight: 100;
  display: flex;
}

.viewFile {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.version,
.updated {
  width: 45%;
  text-align: left;
}
.colon {
  width: 10%;
}

.value {
  width: 45%;
}

.policy_card {
  /* background-color: #e6f2ff; */
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  transition: transform 0.6s ease, background-color 0.3s ease;
}

.policy_card:hover {
  transform: scale(1.05);
  /* background-color: #e6f2ff; */
}
.policy_card .policy_name {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Nunito";
}

.policy_card .policy_details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.policy_card .policy_details .version,
.policy_card .policy_details .updated {
  font-size: 16px;
}

.policy_card .policy_details .colon {
  margin: 0 10px;
}

.policy_card .policy_details .value {
  font-size: 16px;
}

.policy_card .viewFile {
  color: #3384b1;
  /* text-decoration: underline; */
  cursor: pointer;
  font-weight: bold;
}

#fileFrame {
  height: 77%;
  width: 100%;
}

#fileFrame {
  display: block;
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
  border: 1px solid red;
}

.paginationNumbers_policy {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8%;
  gap: 1%;
  margin-top: 1.5vw;
}

.custom-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other content */
}

.custom-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  overflow: auto;
  position: relative;
  height: 80vh;
  width: 50vw;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #999;
}

.dialog-content {
  padding: 20px;
  width: 94%;
  height: 94%;
}

.file_dialog_container {
  width: 100%;
  height: 102%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 20px;
}

.no_policies {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 37px;
  font-family: "Nunito";
  color: rgba(0, 0, 0);
  height: 88vh;
}

.policyContainer {
  width: 91%;
  height: 89vh;
  max-height: 90vh;
}
/* added by havil */
@media only screen and (max-width: 1600px){
  .paginationNumbers{
    margin-top: 6rem;
  }
}
/*  */

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .policies {
    height: 74%;
    overflow-y: scroll;
    margin-bottom: 1rem;
  }
  .policy_card {
    width: 23%;
  }
  .policy_name {
    font-size: 22px !important;
    margin-bottom: 1rem !important;
  }
  .policy_card .policy_details .version,
  .policy_card .policy_details .updated {
    font-size: 14px !important;
  }
  .policyContainer {
    max-height: unset;
    height: 85vh;
    overflow-y: auto;
  }
  .paginationNumbers_policy {
    height: unset;
    margin-top: unset;
  }
  .paginationNumbers{
    margin-top: 9rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .policies {
    height: 99%;
    width: 100%;
    display: flex;
    gap: 3rem;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: flex-start;
    align-items: start;
    margin-top: 2.5vw;
    margin-left: 2.5vw;
    margin-bottom: 0;
    margin-right: 0;
  }

  .policyContainer {
    width: 89%;
    height: auto;
    max-height: 90vh;
    overflow-y: auto;
    overflow: hidden auto;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .policy_card {
    width: 32vw;
  }

  .policy_card .policy_name {
    font-size: 3vw;
  }

  .policy_card {
    margin-bottom: 16px;
  }
  .custom-dialog {
    background-color: white;
    padding: 0vw;
    border-radius: 8px;
    overflow: auto;
    position: relative;
    height: 77vh;
    width: 92vw;
  }
}
